/**
 * The max time a record stays in memory cache.
 * React Admins dataProvider fns "create", "udpate", "updateMany", "delete", "deleteMany" invalidates the cache for that record.
 * Hard refresh also invalidates the cache.
 *
 * @example
 * const RESOURCE_CACHE = {
 *   office: '1h 15m 30s', // 1 hour + 15 minutes + 30 seconds
 *   team: '12m', // 12 minutes
 * };
 */
export const RESOURCE_CACHE = {
  'offices': '5m',
  'teams': '5m',
  'participants': '5m',
  'surfaces': '15m',
  'venues': '15m',
  'categories': '30m',
  'divisions': '30m',
  'classes': '30m',
};
