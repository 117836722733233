import { authService } from '../index';

const request = config => {
  // getAccessToken will ensure a valid access token, otherwise will reject
  const identity = authService.getIdentity()
  if (identity) {
    config.headers['X-Identity'] = identity.id;
  }
  return config;
}

export default (instance) => {
  instance.interceptors.request.use(request);
}
