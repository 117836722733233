import apiClient from '../apiClient';

const getImageResource = resource => {
  switch (resource) {
    case 'teams':
    case 'offices':
      return 'logos';
    case 'participants':
    case 'profiles':
      return 'pictures';
    case 'pages':
    case 'posts':
      return 'files';
    case 'gamedocuments':
    case 'officialclaimdocuments':
      return resource;
    default:
      return null;
  }
}

export const getImageProperty = resource => {
  switch (resource) {
    case 'teams':
    case 'offices':
      return 'logo';
    case 'participants':
    case 'profiles':
      return 'picture';
    case 'pages':
    case 'posts':
      return 'cover';
    case 'gamedocuments':
    case 'officialclaimdocuments':
      return 'file';
    default:
      return null;
  }
}

export const hasImage = (resource, data) => {
  const property = getImageProperty(resource);
  if (!property) return false;
  return !!data[property];
}

const isFile = input => {
  if (!input) return false;
  if (input instanceof Blob) return true;
  if (input.rawFile instanceof Blob) return true;
  return false;
}

export const hasNewImage = (resource, data) => {
  const property = getImageProperty(resource);
  if (!property) return false;

  const image = data[property];
  if (isFile(image)) return true;
  if (Array.isArray(image) && isFile(image[0])) return true;
  return false;
}

const upload = async (resource, image) => {
  const imageResource = getImageResource(resource);
  if (!imageResource) return Promise.reject();

  const data = new FormData();
  if (image instanceof Blob) { // from AvatarInput (pictures)
    data.append('file', image);
  } else if (image && image.rawFile instanceof Blob) { // from ra ImageInput (logos)
    data.append('file', image.rawFile);
  } else if (Array.isArray(image)) { // from aor ImageInput (logos)
    data.append('file', image[0].rawFile);
  } else {
    return Promise.reject();
  }

  const params = {};
  if (imageResource === 'files') {
    params.type = getImageProperty(resource);
  }

  const response = await apiClient({
    url: `/${imageResource}/upload`,
    method: 'POST',
    params,
    data,
  })

  return response.data;
}

export const uploadImage = async (resource, data) => {
  const property = getImageProperty(resource);
  const image = data[property];

  if (hasNewImage(resource, data)) {
    const file = await upload(resource, image)
    data[property] = file;
    data[`${property}Id`] = file.id;
  } else if (Array.isArray(image) && !image.length) {
    // image was removed (results in empty image array)
    data[property] = null;
    data[`${property}Id`] = null;
  }

  return data;
}
