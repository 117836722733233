import { deviceId } from '../deviceService';

const request = config => {
  return deviceId.then(deviceId => {
    config.headers['X-Device'] = deviceId;
    return config;
  }, () => config)
}

export default (instance) => {
  instance.interceptors.request.use(request);
}
