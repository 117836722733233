const getHost = url => new URL(url).hostname;

const response = res => {
  const requestUrl = res.config.url.startsWith('http') ? res.config.url : `${res.config.baseURL}${res.config.url}`
  const requestHost = getHost(requestUrl);
  const responseHost = getHost(res.request.responseURL);

  // reject requests where the response host does not match the request
  if (requestHost !== responseHost) {
    console.log('Mismatch:', requestHost, responseHost); // eslint-disable-line no-console

    const hostError = new Error('Network Error');
    hostError.status = 0;
    hostError.code = 'EHOSTMISMATCH';
    return Promise.reject(hostError);
  }

  return res;
}

export default (instance) => {
  instance.interceptors.response.use(response);
}
