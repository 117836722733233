export const ADD_CERTIFICATION_FLAG = 'ADD_CERTIFICATION_FLAG';
export const REMOVE_CERTIFICATION_FLAG = 'REMOVE_CERTIFICATION_FLAG';

export const addCertificationFlag = (gameId, name, teamId) => ({
  type: ADD_CERTIFICATION_FLAG,
  payload: {
    gameId,
    name,
    teamId,
  }
});

export const removeCertificationFlag = (gameId, name, teamId) => ({
  type: REMOVE_CERTIFICATION_FLAG,
  payload: {
    gameId,
    name,
    teamId,
  }
});
