/* eslint-disable unicorn/no-useless-undefined */
import { mixed, object, string, number, boolean, array, date, lazy, ref } from "yup";
import { validate as isUUID } from 'uuid'

import { MEMBER_POSITIONS, PERIODS, ALL_OFFICIAL_POSITIONS, OLD_OFFICIAL_POSITIONS, OFFICIAL_STATUSES } from "./constants.js";
import { isLegacyRulebookSeason, isPlayerPosition, isTempId, stripTemp } from './util/index.js';

const entityId = number().nullable(true);
const eventId = string().transform(value => {
  if (isUUID(value)) return value; // allow UUIDs
  if (isTempId(value)) return stripTemp(value); // transform temp_UUID to UUID
  if (!isNaN(Number(value))) return value; // allow legacy event ids to be treated as string number
  return undefined; // invalid value, pass undefined to trip required validator if applicable
})

const nullableNumber = defaultValue =>
  number().transform(value => {
    const number = parseFloat(value);
    if (isNaN(number)) return defaultValue;
    return number;
  }).default(defaultValue).nullable(true)

// if entity objects are not required, specify `.default(undefined)`
// otherwise yup will default to {} and try to validate required properties
export const GameTime = object({
  minutes: nullableNumber(0).required(),
  seconds: nullableNumber(0).required(),
}).default(undefined);

export const HockeyGameTime = object({
  minutes: nullableNumber(20).required(),
  seconds: nullableNumber(0).required(),
  period: string().required().oneOf(PERIODS).default('1')
}).default(undefined);

export const SoccerGameTime = object({
  minutes: nullableNumber(1).required(),
  extra: nullableNumber(0).required()
}).default(undefined);

export const BaseballGameTime = object({
  half: nullableNumber(1).oneOf([1, 2]).default(undefined), // first or second half
  period: nullableNumber(1).required(), // inning
}).default(undefined);

export const Infraction = object({
  id: entityId.required(),
  name: string().required()
}).default(undefined);

export const Participant = object({
  id: entityId.required(),
  firstName: string().required(),
  lastName: string().required(),
  fullName: string().when(['firstName', 'lastName'],
    // default the fullName if not present somehow
    (firstName, lastName, schema) => schema.default(`${firstName} ${lastName}`)
  ),
}).default(undefined);

export const Suspension = object().shape({
  game: number().nullable(true).test("game-when-total", "Game is required when Total is present", function (value) {
    const total = this.resolve(ref("total"));
    return value || !total
  }),
  total: number().notRequired().nullable(true),
  id: eventId.notRequired().nullable(true),
  purgeId: eventId.notRequired().nullable(true)
}).default(null)

export const Member = object({
  id: entityId,
  participantId: entityId.required(),
  participant: Participant.required(),
  positions: array().transform(input => {
    const value = Array.isArray(input) ? input : [input];
    return value
      .filter(Boolean)
      .filter(p => MEMBER_POSITIONS.includes(p))
  }).min(1),
  number: mixed().when('positions', (positions, schema) => {
    if (!positions.some(isPlayerPosition)) return schema.nullable(true);
    return nullableNumber(0).required();
  }),
  isCaptain: boolean().default(false),
  isAlternate: boolean().default(false),
  isAffiliate: boolean().default(false),
  isReleased: boolean().default(false),
  isOverage: boolean().default(false),
  isUnderage: boolean().default(false),
  isStarter: boolean().when('positions', (positions, schema) => {
    if (!positions.includes('G')) return schema.strip()
    return schema.required().default(false);
  }),
  suspension: Suspension.nullable(true)
}).default(undefined);

export const Official = object({
  id: eventId.required(),
  participantId: entityId.default(undefined),
  participant: object({
    id: entityId.default(undefined),
    firstName: string().required(),
    lastName: string().required(),
    fullName: string().when(['firstName', 'lastName'],
      // default the fullName if not present somehow
      (firstName, lastName, schema) => schema.default(`${firstName} ${lastName}`)
    ),
  }).required()
}).default(undefined);

export const CertificationFlag = object({
  name: string().required(),
  teamId: entityId.notRequired(),
}).default(undefined);

export const OfficialSignature = object({
  officialId: string(), // adhoc
  participantId: mixed().when('officialId', (officialId, schema) => {
    if (typeof officialId !== 'undefined') return schema.notRequired().nullable(true);
    return entityId.required()
  }),
  signature: string()
}).default(undefined);

export const KeeperSignature = object({
  name: string().required(),
  signature: string().required()
}).default(undefined);

export const GameCreated = object({
  startTime: date(),
  endTime: date(),
  timezone: string(),
  arenaId: entityId,
  homeTeamId: entityId,
  awayTeamId: entityId,
  status: string(),

  number: string(),
  seasonId: string(),
  division: string(),
  gender: string(),
  category: string(),
  categoryId: string(),
  scheduleId: entityId,
  groupId: entityId,
  comments: string().nullable(true),
  isApproved: boolean(),
})

export const GameRescheduled = object({
  date: date().nullable(true),
  startTime: date(),
  endTime: date(),
  timezone: string(),
  arenaId: entityId,
  homeTeamId: entityId,
  awayTeamId: entityId,
  status: string().nullable(true),
  comments: string().nullable(true),
})

export const GameUpdated = object({
  number: string().nullable(true),
  seasonId: string().nullable(true),
  division: string().nullable(true),
  gender: string().nullable(true),
  category: string().nullable(true),
  categoryId: string().nullable(true),
  scheduleId: entityId,
  groupId: entityId,
  comments: string().nullable(true),
})

export const GameDeleted = object({})

export const AssignSettingsUpdated = object({
  officeId: entityId,
  systemId: entityId,
  minReferee: nullableNumber(),
  minLinesperson: nullableNumber(),
  minOfficial: nullableNumber(),
  minScorekeeper: nullableNumber(),
  minTimekeeper: nullableNumber(),
  minGrade: nullableNumber(),
  minRefereeGrade: nullableNumber(),
  minLinespersonGrade: nullableNumber(),
  minAge: nullableNumber(),
  feesId: entityId,
  fees: object({
    Referee: nullableNumber(),
    Linesperson: nullableNumber(),
    LinespersonTwo: nullableNumber(),
    LinespersonThree: nullableNumber(),
    Official: nullableNumber(),
    Scorekeeper: nullableNumber(),
    Timekeeper: nullableNumber(),
    Supervisor: nullableNumber(),
  }).default(undefined),
  status: string(),
})

export const GameStarted = object({
  periods: array().of(GameTime),
})

export const GameEnded = object({
  gameTime: GameTime.required()
})

export const GameForfeited = object({
  teamId: entityId.required(),
  notes: string(),
  adminNotes: string(),
  score: lazy(value => {
    const shapes = Object.keys(value).reduce((acc, key) => ({
      ...acc,
      [key]: nullableNumber(0)
    }), {})
    return object().shape(shapes)
  })
})

export const LineupUpdated = object({
  teamId: entityId.required(),
  members: array().of(Member).compact()
})

export const BaseballLineupUpdated = object({
  teamId: entityId.required(),
  members: array().of(Member.shape({
    battingOrder: number().nullable(true).default(null),
  })).compact()
})

export const LineupApproved = object({
  teamId: entityId.required(),
  participantId: entityId.required(),
  signature: string().required()
})

export const LineupExtraAdded = object({
  teamId: entityId.required(),
  member: Member.required(),
})

export const BaseballLineupExtraAdded = object({
  teamId: entityId.required(),
  member: Member.shape({
    battingOrder: number().nullable(true).default(null),
  }).required(),
})

export const LineupExtraRemoved = object({
  teamId: entityId.required(),
  participantId: entityId.required(),
})

export const OfficialDelegated = object({
  officialId: string().required(),
  position: string().oneOf(ALL_OFFICIAL_POSITIONS).required(),
  officeId: entityId,
  payOfficeId: entityId,
  feesId: entityId,
})

export const OfficialRequested = object({
  official: Official.required(),
  position: string().oneOf(ALL_OFFICIAL_POSITIONS).required(),
  notes: string(),
})

export const OfficialAssigned = object({
  official: Official.required(),
  position: string().oneOf(ALL_OFFICIAL_POSITIONS).required(),
  notes: string(),
})

export const OfficialAccepted = object({
  participantId: entityId.required(),
  notes: string(),
})

export const OfficialDeclined = object({
  participantId: entityId.required(),
  notes: string(),
  removed: boolean()
})

export const OfficialAdded = object({
  official: Official.required(),
  positions: array().of(string().oneOf(OLD_OFFICIAL_POSITIONS)).compact().notRequired().nullable(true),
  position: mixed().when('positions', (positions, schema) => {
    if (typeof positions !== 'undefined') return schema.notRequired().nullable(true);
    return string().oneOf(ALL_OFFICIAL_POSITIONS).required();
  }),
  officeId: entityId,
  feesId: entityId,
  notes: string(),
  signature: string(),
})

export const OfficialUpdated = object({
  officialId: string(), // adhoc
  participantId: mixed().when('officialId', (officialId, schema) => {
    if (typeof officialId !== 'undefined') return schema.notRequired().nullable(true);
    return entityId.required()
  }),
  positions: array().of(string().oneOf(OLD_OFFICIAL_POSITIONS)).compact().notRequired().nullable(true),
  position: mixed().when('positions', (positions, schema) => {
    if (typeof positions !== 'undefined') return schema.notRequired().nullable(true);
    return string().oneOf(ALL_OFFICIAL_POSITIONS).required();
  }),
  status: string().oneOf(OFFICIAL_STATUSES)
})

export const OfficialRemoved = object({
  officialId: string(), // adhoc
  participantId: mixed().when('officialId', (officialId, schema) => {
    if (typeof officialId !== 'undefined') return schema.notRequired().nullable(true);
    return entityId.required()
  }),
  isNoShow: boolean()
})

export const KeepersUpdated = object({
  scorekeeper: string().nullable(true),
  timekeeper: string().nullable(true)
})

export const GoalScored = object({
  teamId: entityId.required(),
  participantId: mixed().when('isOwnGoal', (isOwnGoal, schema) => {
    if (isOwnGoal) return schema.notRequired().nullable(true);
    return entityId.required()
  }),
  assistIds: array().of(entityId).compact(),
  gameTime: GameTime.required(),
})

export const GoalEdited = object({
  goalId: eventId.required(),
  teamId: entityId.required(),
  participantId: mixed().when('isOwnGoal', (isOwnGoal, schema) => {
    if (isOwnGoal) return schema.notRequired().nullable(true);
    return entityId.required()
  }),
  assistIds: array().of(entityId).compact(),
  gameTime: GameTime.required(),
})

export const GoalDeleted = object({
  goalId: eventId.required()
})

export const PenaltyStarted = (isLegacyPenalty) => object({
  teamId: entityId.required(),
  participantId: entityId.required(),
  servedById: entityId.notRequired(),
  infraction: string().required(),
  duration: string().required(),
  gameTime: GameTime.required(),
  code: string().nullable(true).notRequired(),
  infractionId: isLegacyPenalty ? undefined : eventId.required(),
  optionId: isLegacyPenalty ? undefined : eventId.required(),
  accumulationId: isLegacyPenalty ? undefined : eventId.notRequired().nullable(true),
  isInjured: isLegacyPenalty ? undefined : boolean().nullable(true)
})

export const PenaltyEnded = object({
  penaltyId: eventId.required(),
  endTime: GameTime.required()
})

export const PenaltyEdited = (isLegacyPenalty) => object({
  penaltyId: eventId.required(),
  teamId: entityId.required(),
  participantId: entityId.notRequired(),
  servedById: entityId.notRequired(),
  infraction: string().notRequired(),
  duration: string().notRequired(),
  gameTime: GameTime,
  code: string().nullable(true).notRequired(),
  infractionId: isLegacyPenalty ? undefined : eventId.required(),
  optionId: isLegacyPenalty ? undefined : eventId.required(),
  accumulationId: isLegacyPenalty ? undefined : eventId.notRequired().nullable(true),
  isInjured: isLegacyPenalty ? undefined : boolean().nullable(true)
})

export const PenaltyDeleted = object({
  penaltyId: eventId.required()
})

export const GoalieChanged = object({
  gameTime: GameTime.required(),
  teamId: entityId.required(),
  onParticipantId: entityId.notRequired()
})

export const GoalieChangeDeleted = object({
  goalieChangeId: eventId.required()
})

export const GoalieChangeEdited = object({
  goalieChangeId: eventId.required(),
  gameTime: GameTime.required(),
  teamId: entityId.required(),
  onParticipantId: entityId.notRequired()
})

export const SettingsUpdated = object({
  periods: array().of(GameTime).required(),
})

export const NotesUpdated = object({
  notes: string().nullable(true),
  adminNotes: string().nullable(true),
})

export const CertificationFlagsUpdated = object({
  flags: array().of(CertificationFlag).compact()
})

export const ScoreUpdated = object({
  score: lazy(value => {
    const shapes = Object.keys(value).reduce((acc, key) => ({
      ...acc,
      [key]: nullableNumber(0).required(),
    }), {})

    return object().shape(shapes)
  })
})

export const ScoresheetApproved = object({
  unreviewed: boolean().nullable(true),
  officials: array().of(OfficialSignature).compact(),
  scorekeeper: KeeperSignature,
  timekeeper: KeeperSignature,
  emails: array().of(string()).compact()
})

export const ScoresheetDisapproved = object({})

export const ScoresheetCertified = object({})

export const ScoresheetDecertified = object({})

export const ScoresheetReset = object({})

export const GoalieShotsUpdated = object({
  goalies: array().of(object({
    participantId: entityId.required(),
    teamId: entityId.required(),
    totals: array().of(nullableNumber(null)),
  }))
})

export const ThrowsInningsUpdated = object({
  teamId: entityId.required(),
  throwsInnings: array().of(object({
    participantId: entityId.required(),
    teamId: entityId.required(),
    throws: nullableNumber(null),
    inningsPitched: nullableNumber(null),
  }))
})

export const schemas = {
  Hockey: {
    gameCreated: GameCreated,
    gameRescheduled: GameRescheduled,
    gameUpdated: GameUpdated,
    gameDeleted: GameDeleted,

    assignSettingsUpdated: AssignSettingsUpdated,

    gameStarted: GameStarted.shape({
      periods: array().of(HockeyGameTime),
    }),
    gameEnded: GameEnded.shape({
      gameTime: HockeyGameTime.required()
    }),
    gameForfeited: GameForfeited,

    lineupUpdated: LineupUpdated,
    lineupApproved: LineupApproved,
    lineupExtraAdded: LineupExtraAdded,
    lineupExtraRemoved: LineupExtraRemoved,

    officialDelegated: OfficialDelegated,
    officialRequested: OfficialRequested,
    officialAssigned: OfficialAssigned,
    officialAccepted: OfficialAccepted,
    officialDeclined: OfficialDeclined,
    officialAdded: OfficialAdded,
    officialUpdated: OfficialUpdated,
    officialRemoved: OfficialRemoved,

    keepersUpdated: KeepersUpdated,

    goalScored: GoalScored.shape({
      gameTime: HockeyGameTime.required(),
      isShorthanded: boolean().required().default(false),
      isPowerplay: boolean().required().default(false),
      isEmptyNet: boolean().required().default(false),
      isPenaltyShot: boolean().required().default(false),
    }),
    goalEdited: GoalEdited.shape({
      gameTime: HockeyGameTime.required(),
      isShorthanded: boolean().required().default(false),
      isPowerplay: boolean().required().default(false),
      isEmptyNet: boolean().required().default(false),
      isPenaltyShot: boolean().required().default(false),
    }),
    goalDeleted: GoalDeleted,

    penaltyStarted: (isLegacyPenalty) => PenaltyStarted(isLegacyPenalty).shape({
      gameTime: HockeyGameTime.required(),
      startTime: HockeyGameTime.required(),
    }),
    penaltyEnded: PenaltyEnded.shape({
      endTime: HockeyGameTime.required(),
    }),
    penaltyEdited: (isLegacyPenalty) => PenaltyEdited(isLegacyPenalty).shape({
      gameTime: HockeyGameTime,
      startTime: HockeyGameTime,
      endTime: HockeyGameTime
    }),
    penaltyDeleted: PenaltyDeleted,

    goalieChanged: GoalieChanged.shape({
      gameTime: HockeyGameTime,
    }),
    goalieChangeDeleted: GoalieChangeDeleted,
    goalieChangeEdited: GoalieChangeEdited.shape({
      gameTime: HockeyGameTime,
    }),

    settingsUpdated: SettingsUpdated.shape({
      periods: array().of(HockeyGameTime).required(),
    }),
    notesUpdated: NotesUpdated,
    goalieShotsUpdated: GoalieShotsUpdated,
    certificationFlagsUpdated: CertificationFlagsUpdated,
    scoreUpdated: ScoreUpdated,

    scoresheetApproved: ScoresheetApproved,
    scoresheetDisapproved: ScoresheetDisapproved,
    scoresheetCertified: ScoresheetCertified,
    scoresheetDecertified: ScoresheetDecertified,
    scoresheetReset: ScoresheetReset,
  },

  Soccer: {
    gameCreated: GameCreated,
    gameRescheduled: GameRescheduled,
    gameUpdated: GameUpdated,
    gameDeleted: GameDeleted,

    assignSettingsUpdated: AssignSettingsUpdated,

    gameStarted: GameStarted,
    gameEnded: GameEnded.shape({
      gameTime: SoccerGameTime.required()
    }),
    gameForfeited: GameForfeited,

    lineupUpdated: LineupUpdated,
    lineupApproved: LineupApproved,
    lineupExtraAdded: LineupExtraAdded,
    lineupExtraRemoved: LineupExtraRemoved,

    officialDelegated: OfficialDelegated,
    officialRequested: OfficialRequested,
    officialAssigned: OfficialAssigned,
    officialAccepted: OfficialAccepted,
    officialDeclined: OfficialDeclined,
    officialAdded: OfficialAdded,
    officialUpdated: OfficialUpdated,
    officialRemoved: OfficialRemoved,

    keepersUpdated: KeepersUpdated,

    goalScored: GoalScored.shape({
      gameTime: SoccerGameTime.required(),
      isPenaltyShot: boolean().required().default(false),
      isOwnGoal: boolean().required().default(false)
    }),
    goalEdited: GoalEdited.shape({
      gameTime: SoccerGameTime.required(),
      isPenaltyShot: boolean().required().default(false),
      isOwnGoal: boolean().required().default(false)
    }),
    goalDeleted: GoalDeleted,

    penaltyStarted: (isLegacyPenalty) => PenaltyStarted(isLegacyPenalty).shape({
      gameTime: SoccerGameTime.required(),
    }),
    penaltyEdited: (isLegacyPenalty) => PenaltyEdited(isLegacyPenalty).shape({
      gameTime: SoccerGameTime,
    }),
    penaltyDeleted: PenaltyDeleted,

    // TODO add subtitution game event for soccer
    goalieChanged: GoalieChanged.shape({
      gameTime: SoccerGameTime,
    }),
    goalieChangeDeleted: GoalieChangeDeleted,
    goalieChangeEdited: GoalieChangeEdited.shape({
      gameTime: SoccerGameTime,
    }),

    settingsUpdated: SettingsUpdated,
    notesUpdated: NotesUpdated,
    goalieShotsUpdated: GoalieShotsUpdated,
    certificationFlagsUpdated: CertificationFlagsUpdated,
    scoreUpdated: ScoreUpdated,

    scoresheetApproved: ScoresheetApproved,
    scoresheetDisapproved: ScoresheetDisapproved,
    scoresheetCertified: ScoresheetCertified,
    scoresheetDecertified: ScoresheetDecertified,
    scoresheetReset: ScoresheetReset,
  },

  Baseball: {
    gameCreated: GameCreated,
    gameRescheduled: GameRescheduled,
    gameUpdated: GameUpdated,
    gameDeleted: GameDeleted,

    assignSettingsUpdated: AssignSettingsUpdated,

    gameStarted: GameStarted,
    gameEnded: GameEnded.shape({
      gameTime: BaseballGameTime.required()
    }),
    gameForfeited: GameForfeited,

    lineupUpdated: BaseballLineupUpdated,
    lineupApproved: LineupApproved,
    lineupExtraAdded: BaseballLineupExtraAdded,
    lineupExtraRemoved: LineupExtraRemoved,

    officialDelegated: OfficialDelegated,
    officialRequested: OfficialRequested,
    officialAssigned: OfficialAssigned,
    officialAccepted: OfficialAccepted,
    officialDeclined: OfficialDeclined,
    officialAdded: OfficialAdded,
    officialUpdated: OfficialUpdated,
    officialRemoved: OfficialRemoved,

    // will need to revisit "goals" in baseball
    goalScored: GoalScored.shape({
      gameTime: BaseballGameTime.required(),
    }),
    goalEdited: GoalEdited.shape({
      gameTime: BaseballGameTime.required(),
    }),
    goalDeleted: GoalDeleted,

    penaltyStarted: (isLegacyPenalty) => PenaltyStarted(isLegacyPenalty).shape({
      gameTime: BaseballGameTime.required(),
    }),
    penaltyEdited: (isLegacyPenalty) => PenaltyEdited(isLegacyPenalty).shape({
      gameTime: BaseballGameTime,
    }),
    penaltyDeleted: PenaltyDeleted,

    notesUpdated: NotesUpdated,
    certificationFlagsUpdated: CertificationFlagsUpdated,
    scoreUpdated: ScoreUpdated,

    scoresheetApproved: ScoresheetApproved,
    scoresheetDisapproved: ScoresheetDisapproved,
    scoresheetCertified: ScoresheetCertified,
    scoresheetDecertified: ScoresheetDecertified,
    scoresheetReset: ScoresheetReset,

    throwsInningsUpdated: ThrowsInningsUpdated,
  }
}

export const validate = (eventType, event, sport, seasonId) => {
  if (!sport) return Promise.reject(new Error(`Undefined sport`));
  let schema = schemas[sport][eventType];
  if (!schema) return Promise.reject(new Error(`Undefined event type ${eventType}`));

  if (typeof schema === "function") {
    if (!seasonId) return Promise.reject(new Error(`Undefined seasonId`));
    const isLegacy = isLegacyRulebookSeason(seasonId);
    schema = schema(isLegacy);
  }

  return schema.validate(event, {
    stripUnknown: true
  });
}
