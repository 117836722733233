import { authService } from '../index';

const isAuthRequest = config => config?.url.endsWith('/oauth/token');

// warning: loopback is non-standard and uses 403 instead of 401 per spec
const isTokenExpiry = res => res && res.status == 403 && res.data?.error_description === 'Access token is expired';
const isTokenFailure = res => res && res.status == 500 && res.data?.error?.message.includes('Cannot call AccessToken.findById()')
const shouldRefresh = res => isTokenExpiry(res) || isTokenFailure(res);

const request = config => {
  if (config.skipAuth) return config;

  if (isAuthRequest(config)) {
    if ('authorization' in config.headers) {
      // authorization header shouldn't be on auth requests
      delete config.headers.authorization;
    }
    return config;
  }

  // getAccessToken will ensure a valid access token, otherwise will reject
  return authService.getAccessToken()
    .then(function(token) {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    }).catch(() => {
      // failed to gain a token, mimic a loopback-style response
      return Promise.reject({
        config,
        status: 403,
        headers() {},
        body: {
          error: 'invalid_grant',
          error_description: 'Access token is invalid'
        }
      })
    });
}

const responseError = instance => err => {
  const { config } = err;
  if (!shouldRefresh(err.response)) return Promise.reject(err);

  // attempt to force a refresh and reattempt the request if possible
  const forceRefresh = true;

  // prevent infinite loop in case of server shenanigans
  config.attempts = config.attempts ? config.attempts + 1 : 1;
  if (config.attempts >= 3) return Promise.reject(err);

  return authService.getAccessToken(forceRefresh)
    .then(function(token) {
      config.headers.authorization = `Bearer ${token}`;
      return instance(config);
    })
    .catch(() => Promise.reject(err)); // reject with original error
};

export default (instance) => {
  instance.interceptors.request.use(request);
  instance.interceptors.response.use(null, responseError(instance));
}
