const startsWith = query => ({ ilike: `${query}%` })
const endsWith = query => ({ ilike: `%${query}` })
const name = (firstName, lastName) => ({ firstName: startsWith(firstName), lastName: startsWith(lastName) })
const join = (arr, start, end) => arr.slice(start, end).join(' ');

export const filterParticipants = input => {
  const names = input.trim().split(" ").map(name => name.trim());
  if (names.length === 1) { // john
    return { or: [
      { firstName: startsWith(input) },
      { lastName: startsWith(input) },
      { HCRId: startsWith(input) },
      { HCRId: endsWith(input) },
    ] }
  }

  if (names.length === 2) { // john doe
    return { or: [
      name(names[0], names[1]),
      { firstName: startsWith(input) },
      { lastName: startsWith(input) }
    ] }
  }

  const permutations = [];
  for (let i = 1; i < names.length; i++) {
    permutations.push(name(join(names, 0, i), join(names, i)))
  }
  return { or: permutations }
}
