export const getCertificationFlags = (state) => {
  if (!state || !state.flags) return [];

  return [...state.flags];
}

export const getCertificationFlag = (state, flagName, teamId) => {
  if (!state) return;

  const flags = getCertificationFlags(state);
  return flags.find(flag => flag.name === flagName && (!teamId || teamId === flag.teamId));
}

export const hasCertificationFlags = (state) => {
  if (!state) return;

  const flags = getCertificationFlags(state);
  return flags.length > 0
}
