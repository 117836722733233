import moment from 'moment';
import { setUser as setSentryUser } from '@sentry/browser';
import { createInstance } from 'localforage'

import apiClient from './apiClient';

const AUTH_URL = `${process.env.API_HOST}/oauth/token`

const TOKEN_KEY = 'tokens';
const USER_KEY = 'user';
const IDENTITY_KEY = 'identity';

const storage = createInstance({
  name: 'hisports',
  storeName: 'auth'
});

export class AuthService {
  constructor(clientId, clientSecret) {
    this._clientId = clientId;
    this._clientSecret = clientSecret;

    this._subscriptions = [];

    this._tokens = undefined;
    this._user = undefined;

    this._loggedIn = false;
    this._account = undefined;
    this._identity = undefined;
    this._identities = [];
    this._permissions = [];
    this._scopes = [];
    this._notices = [];
    this._flags = [];

    this._waitForRestore = this._restore()
      .then(() => this.init())
      .finally(() => this._notify())

    this._ready = false;
    this.waitForInit = this._waitForRestore
      .then(() => this._getAccount())
      .finally(() => {
        this._ready = true;
        this._notify();
      })
  }

  init() {
    this._loggedIn = this._tokens != null;
  }

  lookupSma(username) {
    return apiClient('/accounts/ssoCheckRedirect', {
      method: 'GET',
      params: { username },
      skipAuth: true
    })
  }

  loginWithSma(accessToken, username) {
    const payload = {
      grant_type: 'password',
      client_id: this._clientId,
      client_secret: this._clientSecret,
      access_token: accessToken,
      username,
      password: 'third-party-token',
    }
    return apiClient.post(AUTH_URL, payload)
      .then(res => this._saveTokens(res))
      .then(res => {
        this._saveUser({ username });
        this._loggedIn = true;

        return res.data;
      })
      .then(() => this._getAccount({ isLogin: true }))
      .catch(res => {
        // unexpected error, not a request object from api
        if (!res?.response) return Promise.reject(res);
        return Promise.reject(res.response)
      })
      .catch(err => this._clear(err))
      .finally(() => {
        this._notify()
      });
  }

  login(username, password, volunteer = false) {
    const payload = {
      grant_type: 'password',
      client_id: this._clientId,
      client_secret: this._clientSecret,
      username,
      password
    }
    if (volunteer) {
      payload.volunteer = true;
    }
    return apiClient.post(AUTH_URL, payload)
      .then(res => this._saveTokens(res))
      .then(res => {
        this._saveUser({ username, volunteer });
        this._loggedIn = true;

        return res.data;
      })
      .then(() => this._getAccount({ isLogin: true }))
      .catch(res => {
        // unexpected error, not a request object from api
        if (!res?.response) return Promise.reject(res);
        return Promise.reject(res.response)
      })
      .catch(err => this._clear(err))
      .finally(() => {
        this._notify()
      });
  }

  logout() {
    return this._clear()
      .finally(() => {
        this._notify()
      });
  }

  isLoggedIn() {
    return this._loggedIn;
  }

  isVolunteer() {
    return this._user?.volunteer;
  }

  getAccount() {
    return this._account;
  }

  getUsername() {
    return this._account?.username || this?._user?.username;
  }

  getIdentity() {
    if (!this._identity) return;
    return this._identities.find(identity => identity.id === this._identity)
  }

  getIdentities() {
    return this._identities;
  }

  getScopes() {
    return this._scopes;
  }

  getPermissions() {
    return this._permissions;
  }

  getNotices() {
    return this._notices;
  }

  getFlags() {
    return this._flags;
  }

  setIdentity(identity, refresh = true) {
    if (!identity) return;

    this._identity = identity.id;
    return Promise.all([
      storage.setItem(IDENTITY_KEY, identity.id).catch(e => {}),
      refresh && this._getAccount(),
    ]).then(() => this.getIdentity())
      .finally(() => {
        this._notify()
      });
  }

  isAccessTokenValid(_tokens) {
    if (!_tokens || !_tokens.access_token || !_tokens.expiry) return false;
    return moment.utc().isBefore(_tokens.expiry);
  }

  getAccessToken(forceRefresh = false) {
    return this._getTokens().then(_tokens => {
      if (!_tokens) return Promise.reject();

      if (forceRefresh || !this.isAccessTokenValid(_tokens)) {
        return this.refresh().then(() => this.getAccessToken());
      }

      return Promise.resolve(_tokens.access_token);
    })
  }

  refresh() {
    // prevent multiple refresh requests by multiple requests
    if (this._pendingRefresh != null) return this._pendingRefresh;

    this._pendingRefresh = this._refresh();
    this._pendingRefresh.finally(() => {
      this._pendingRefresh = undefined;
    });

    return this._pendingRefresh;
  }

  subscribe(handler) {
    this._subscriptions.push(handler);
    return () => this.unsubscribe(handler);
  }

  unsubscribe(handler) {
    this._subscriptions = this._subscriptions.filter(subscription => subscription !== handler)
  }

  refreshAccount() {
    return this._getAccount({ forceRefresh: true });
  }

  _notify() {
    this._subscriptions.forEach(handler => handler())
  }

  _refresh() {
    return this._getTokens().then(({ refresh_token }) => {
      if (refresh_token == null) return Promise.reject();

      return apiClient.post(AUTH_URL, {
        grant_type: 'refresh_token',
        client_id: this._clientId,
        client_secret: this._clientSecret,
        refresh_token
      })
    })
      .then(res => this._saveTokens(res))
      .then(res => res.data)
      .catch(err => this._clear(err));
  }

  _getAccount({ forceRefresh = false, isLogin = false } = {}) {
    if (!this.isLoggedIn() && !forceRefresh) return Promise.resolve();

    const params = {};
    if (isLogin) {
      params.isLogin = Number(isLogin);
    }

    return apiClient('/accounts/current', {
      headers: {
        'X-Identity': this._identity,
      },
      params,
    }).then(res => res.data)
      .then(({ participantId, permissions, identities, seasonId, notices, flags, ...account }) => {
        setSentryUser({ id: account.id, username: account.username })

        this._account = account;
        this._identities = identities || [];
        this._permissions = permissions || [];
        this._scopes = Array.from(new Set(this._permissions.flatMap(p => p.scopes)));
        this._officeId = this._permissions.find(p => !p.inherited && p.officeIds?.length)?.officeIds?.[0];
        this._notices = notices || [];
        this._flags = flags || [];

        const identity = (this._identity && this._identities.find(identity => identity.id === this._identity))
          || this._identities.find(identity => identity.participantId === participantId)
          || this._identities.find(identity => identity.isPrimary)
          || this._identities[0];

        this._seasonId = identity?.tenant?.seasonId;

        if (this._identity === identity?.id) return;
        this.setIdentity(identity, false);
      })
      .catch(e => {
        const error = e?.response?.data?.error_description;
        if (error === 'Access token is invalid') return this._clear(e);
        throw e;
      })
      .finally(() => this._notify())
  }

  _getTokens() {
    return this._waitForRestore.then(() => this._tokens);
  }

  _saveTokens(response) {
    this._tokens = response.data; // access_token, expires_in, refresh_token, token_type
    this._tokens.expiry = moment.utc()
      .add(this._tokens.expires_in, 'seconds')
      .toISOString();

    return storage.setItem(TOKEN_KEY, this._tokens)
      .then(() => response);
  }

  _saveUser(user) {
    this._user = user;
    return storage.setItem(USER_KEY, this._user);
  }

  _restore() {
    const user = storage.getItem(USER_KEY).then(user => {
      this._user = user;
    }).catch(e => {})
    const identity = storage.getItem(IDENTITY_KEY).then(identity => {
      this._identity = identity;
    }).catch(e => {})
    const tokens = storage.getItem(TOKEN_KEY).then(tokens => {
      this._tokens = tokens;
    }).catch(e => {})

    const queue = Promise.all([user, identity, tokens])
    queue.then(() => {
      localStorage.clear()
    }).catch(e => {})

    return queue;
  }

  _clearStorage() {
    return Promise.all([
      storage.removeItem(TOKEN_KEY),
      storage.removeItem(IDENTITY_KEY),
    ])
  }

  _clear(err) {
    const cleared = this._clearStorage();
    this._loggedIn = false;
    return cleared.then(() => {
      setSentryUser({ id: undefined, username: undefined })

      if (err) return Promise.reject(err);
    })
  }
}
