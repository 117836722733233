const ilike = query => ({ ilike: `%${query}%` })
const name = (firstName, lastName) => ({ 'participant.firstName': ilike(firstName), 'participant.lastName': ilike(lastName) })
const join = (arr, start, end) => arr.slice(start, end).join(' ');

export const filterOfficialParticipants = input => {
  const names = input.trim().split(" ").map(name => name.trim());
  if (names.length === 1) { // john
    return { or: [
      { 'participant.firstName': ilike(input) },
      { 'participant.lastName': ilike(input) },
      { HCRId: ilike(input) },
    ] }
  }

  if (names.length === 2) { // john doe
    return { or: [
      name(names[0], names[1]),
      { 'participant.firstName': ilike(input) },
      { 'participant.lastName': ilike(input) }
    ] }
  }

  const permutations = [];
  for (let i = 1; i < names.length; i++) {
    permutations.push(name(join(names, 0, i), join(names, i)))
  }
  return { or: permutations }
}
