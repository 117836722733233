export class AuthClient {
  constructor(authService) {
    this.authService = authService
  }

  login({ type, username, password, game, team }) {
    if (type === 'scorekeeper') return this.authService.login(game, team, true);
    return this.authService.login(username, password)
  }

  logout() {
    return this.authService.logout();
  }

  checkError(error) {
    // Unauthorized forces logout. API client handles refreshing.
    const message = error?.response?.data?.error_description
    if (message === 'Access token is invalid') return Promise.reject();
    return Promise.resolve();
  }

  checkAuth() {
    return this.authService.getAccessToken()
  }

  getPermissions() {
    const scopes = this.authService.getScopes()
    return Promise.resolve(scopes);
  }
}
