
import { groupBy, orderBy } from '@hisports/common/src/lodash.js';

import { dedupeBy } from "@hisports/parsers";

import { EJECTION, RED, YELLOW, YELLOW_RED } from "../constants.js";
import { getGameMembers, isInLastMinutes } from "../selectors/index.js";
import { getMemberPositionGroup } from "./members.js";
import { generateId } from "./index.js";

const getPenaltySanction = (sanctions, sanctionMembers, penalty) => {
  const penaltySanctionMember = sanctionMembers.reduce((lowest, member) => {
    const { optionId, infractionId } = penalty;

    if (
      (member.optionId && member.optionId === optionId) ||
      (member.infractionId && member.infractionId === infractionId)
    ) {
      const sanction = sanctions.find(sanction => sanction.id === member.sanctionId);
      if (!lowest || (sanction && sanction.order < lowest.order)) {
        return { ...member, order: sanction.order };
      }
    }
    return lowest;
  }, null);

  if (!penaltySanctionMember) return null;

  const sanction = sanctions.find(sanction => sanction.id === penaltySanctionMember.sanctionId);
  if (!sanction) return null;

  return { ...penaltySanctionMember, sanction };
}

const getLowestSanctionOrderPenalty = (gamePenalties) => {
  const lowest = gamePenalties.reduce((lowest, penalty) => {
    const order = penalty.sanction.order;
    if (!lowest || order < lowest.sanction.order) {
      return penalty;
    }
    return lowest;
  }, null);

  return lowest
}

export const getLeagueSanctionSuspensions = (sanctions, penalties, sport) => {
  const sanctionMembers = sanctions.flatMap(sanction => sanction.members);
  const sanctionOffenses = sanctions.flatMap(sanction => sanction.offenses);
  const penaltyGames = penalties.map(penalty => penalty.game);

  const participantPenaltiesPerGame = penalties.reduce((participantPenaltiesPerGame, penalty) => {
    const { participantId, gameId } = penalty;

    if (!participantPenaltiesPerGame[participantId]) {
      participantPenaltiesPerGame[participantId] = {};
    }

    if (!participantPenaltiesPerGame[participantId][gameId]) {
      participantPenaltiesPerGame[participantId][gameId] = [];
    }

    const penaltySanction = getPenaltySanction(sanctions, sanctionMembers, penalty);
    if (!penaltySanction) return participantPenaltiesPerGame;

    participantPenaltiesPerGame[participantId][gameId].push({ ...penalty, sanction: penaltySanction.sanction });

    return participantPenaltiesPerGame;
  }, {});

  const participantSuspensions = Object.keys(participantPenaltiesPerGame).reduce((participantSuspensions, participantId) => {
    const participantSanctionsCount = {};

    const gameIds = Object.keys(participantPenaltiesPerGame[participantId]);
    const sortedGameIds = orderBy(gameIds, gameId => penaltyGames.find(game => game.id == gameId).date, 'asc');

    sortedGameIds.forEach(gameId => {
      const gamePenalties = participantPenaltiesPerGame[participantId][gameId];

      // Find the penalty with the lowest sanction order for this game
      const lowestSanctionOrderPenalty = getLowestSanctionOrderPenalty(gamePenalties);
      if (!lowestSanctionOrderPenalty) return;

      const { id: penaltyId, teamId, gameTime, scoresheet, sanction, isInjured } = lowestSanctionOrderPenalty;
      const sanctionId = sanction.id;

      const members = getGameMembers(scoresheet);
      const positionGroup = getMemberPositionGroup(members, participantId);

      if (sanction.positionGroups && sanction.positionGroups.length > 0 && !sanction.positionGroups.includes(positionGroup)) return;

      if (!participantSanctionsCount[sanctionId]) {
        participantSanctionsCount[sanctionId] = 0;
      }

      participantSanctionsCount[sanctionId] += 1;

      const sanctionCount = participantSanctionsCount[sanctionId];
      if (sanctionCount % sanction.accumulationCount !== 0) return;

      const offenses = sanctionOffenses.filter(offense => offense.sanctionId === sanction.id);
      const offenseCount = sanctionCount / sanction.accumulationCount;
      const offense = orderBy(offenses, 'offense', 'desc').find(offense => offenseCount >= offense.offense);

      if (!offense) return;

      let requiredGames = null

      if (offense.durationType !== 'Indefinite') {
        requiredGames = offense.games;

        // if in last minutes of game -> use last minutes games as the required games
        if (offense.lastMinutesGames && isInLastMinutes(scoresheet, gameTime, sport)) {
          requiredGames = offense.lastMinutesGames;
        }

        // if injured on penalty -> add additional injury games to required games
        if (offense.injuryGames && isInjured) {
          requiredGames += offense.injuryGames
        }
      }

      participantSuspensions.push({
        penaltyId,
        participantId: parseInt(participantId),
        gameId: parseInt(gameId),
        teamId,
        sanctionId: offense.sanctionId,
        positionGroup,
        requiredGames,
        durationType: offense.durationType,
        requireDisciplineCommittee: offense.requireDisciplineCommittee
      });
    });


    return participantSuspensions;
  }, []);


  return participantSuspensions;
}

export const SUSPENSION_RULES = [{
  // RULE #1 SOCCER GENERAL: 1 game for red card
  code: 'Game Red',
  sport: 'Soccer',
  rule: (gamePenalties) => {
    return gamePenalties.reduce((suspensions, penalty) => {
      const { id, gameId, participantId, teamId, duration, scoresheet } = penalty
      const hasSuspension = suspensions.some(suspension => suspension.participantId === penalty.participantId)
      const members = getGameMembers(scoresheet)
      const positionGroup = getMemberPositionGroup(members, participantId)

      if (duration === YELLOW_RED && !hasSuspension) {
        suspensions.push({
          gameId,
          penaltyId: id,
          participantId,
          teamId,
          positionGroup,
          code: 'Game Red',
          requiredGames: 1,
          durationType: 'Definite'
        })
      }

      return suspensions
    }, [])
  }
}, {
  // RULE #2 SOCCER GENERAL: 1 game for direct red card
  code: 'Game Red Direct',
  sport: 'Soccer',
  rule: (gamePenalties) => {
    return gamePenalties.reduce((suspensions, penalty) => {
      const { id, gameId, participantId, teamId, duration, scoresheet } = penalty
      const hasSuspension = suspensions.some(suspension => suspension.participantId === penalty.participantId)
      const members = getGameMembers(scoresheet)
      const positionGroup = getMemberPositionGroup(members, participantId)

      if (duration === RED && !hasSuspension) {
        suspensions.push({
          gameId,
          penaltyId: id,
          participantId,
          teamId,
          positionGroup,
          code: 'Game Red Direct',
          requiredGames: 1,
          durationType: 'Definite'
        })
      }

      return suspensions
    }, [])
  }
}, {
  // RULE #1 BASEBALL GENERAL: 1 game for ejection
  code: 'Game Ejection',
  sport: 'Baseball',
  rule: (gamePenalties) => {
    return gamePenalties.reduce((suspensions, penalty) => {
      const { id, gameId, participantId, teamId, duration, scoresheet } = penalty
      const hasSuspension = suspensions.some(suspension => suspension.participantId === penalty.participantId)
      const members = getGameMembers(scoresheet)
      const positionGroup = getMemberPositionGroup(members, participantId)

      if ([EJECTION].includes(duration) && !hasSuspension) {
        suspensions.push({
          gameId,
          penaltyId: id,
          participantId,
          teamId,
          positionGroup,
          code: 'Game Ejection',
          requiredGames: 1,
          durationType: 'Definite'
        })
      }

      return suspensions
    }, [])
  }
}, {
  // RULE #3 SOCCER 3 YELLOW: 1 game for 3 yellows in a row in different games
  code: 'Schedule Yellow',
  sport: 'Soccer',
  isAccumulationRule: true,
  rule: (leaguePenalties) => {
    const isYellowDuration = (gamePenalty, gamePenalties) => {
      const isYellow = gamePenalty.duration === YELLOW

      return isYellow && !gamePenalties.some(({ participantId, teamId, duration }) => {
        const isCurrentMember = gamePenalty.participantId === participantId
        const isCurrentTeam = gamePenalty.teamId === teamId
        const hasYellowRed = duration === YELLOW_RED

        return isCurrentMember && isCurrentTeam && hasYellowRed
      })
    }

    const memberYellowCounts = {}

    return orderBy(leaguePenalties, ['game.date', 'game.startTime'])
      .reduce((suspensions, leaguePenalty) => {
        const currentGamePenalties = leaguePenalties.filter(({ gameId }) => gameId === leaguePenalty.gameId)

        // identify a yellow duration penalty amongst current game penalties
        if (!isYellowDuration(leaguePenalty, currentGamePenalties)) return suspensions

        if (!memberYellowCounts[leaguePenalty.teamId]) {
          memberYellowCounts[leaguePenalty.teamId] = {};
        }
        if (!memberYellowCounts[leaguePenalty.teamId][leaguePenalty.participantId]) {
          memberYellowCounts[leaguePenalty.teamId][leaguePenalty.participantId] = 0
        }
        memberYellowCounts[leaguePenalty.teamId][leaguePenalty.participantId] += 1

        // every 3rd yellow in same team -> new Schedule Yellow suspension
        if (memberYellowCounts[leaguePenalty.teamId][leaguePenalty.participantId] !== 0 && memberYellowCounts[leaguePenalty.teamId][leaguePenalty.participantId] % 3 === 0) {
          const members = getGameMembers(leaguePenalty.scoresheet)
          const positionGroup = getMemberPositionGroup(members, leaguePenalty.participantId)

          suspensions.push({
            gameId: leaguePenalty.game.id,
            penaltyId: leaguePenalty.id,
            participantId: leaguePenalty.participantId,
            teamId: leaguePenalty.teamId,
            positionGroup,
            code: 'Schedule Yellow',
            requiredGames: 1,
            durationType: 'Definite'
          })
        }

        return suspensions
      }, [])
  }
}]

// return all suspensions generated from non accumulation rules for a league
export const getGameSuspensionsFromGamePenalties = (leaguePenalties, sport) => {
  const orderedGameIds = orderBy(dedupeBy('gameId', leaguePenalties), ['game.date', 'game.startTime']).map(({ gameId }) => gameId)
  const leaguePenaltiesByGames = groupBy(leaguePenalties, 'gameId')

  return orderedGameIds.reduce((gameSuspensions, gameId) => {
    const gamePenalties = leaguePenaltiesByGames[gameId]

    return gameSuspensions.concat(SUSPENSION_RULES
      .filter(SUSPENSION_RULE => !SUSPENSION_RULE.isAccumulationRule)
      .filter(SUSPENSION_RULE => SUSPENSION_RULE.sport === sport)
      .flatMap(SUSPENSION_RULE => SUSPENSION_RULE.rule(gamePenalties)))
  }, [])
}

// return all suspensions generated from accumulation rules for a league
export const getAccumulationSuspensionsFromLeaguePenalties = (leaguePenalties, sport) => {
  return SUSPENSION_RULES
    .filter(SUSPENSION_RULE => SUSPENSION_RULE.isAccumulationRule)
    .filter(SUSPENSION_RULE => SUSPENSION_RULE.sport === sport)
    .flatMap(SUSPENSION_RULE => SUSPENSION_RULE.rule(leaguePenalties))
}

// return all suspensions for a league
export const getLeagueSuspensions = (leaguePenalties, sport) => {
  return [
    ...getGameSuspensionsFromGamePenalties(leaguePenalties, sport),
    ...getAccumulationSuspensionsFromLeaguePenalties(leaguePenalties, sport)
  ]
}

export const isSuspensionPurgeable = (suspension, teamId) => {
  // can only purge suspension in own team
  return suspension.type === 'Game' && (suspension.teamId == teamId || suspension.additionalPurgingTeamId == teamId)
}

// sort by automatic suspensions first
export const sortGameSuspensions = gameSuspensions => {
  return orderBy(gameSuspensions, ['durationType', 'code', 'game.date'], ['desc', 'asc', 'asc'])
}

export const getInitialSuspension = (memberValidation = {}, teamId) => {
  let gameSuspensions = (memberValidation.suspensions || []).filter(suspension => suspension.type === 'Game');
  const adminSuspension = (memberValidation.suspensions || []).find(suspension => suspension.type === 'Admin');

  gameSuspensions = sortGameSuspensions(gameSuspensions)

  if (!!adminSuspension) return {
    game: null,
    total: null,
  }

  if (!gameSuspensions.length) return null;

  if (!isSuspensionPurgeable(gameSuspensions[0], teamId)) return {
    id: gameSuspensions[0].id
  }

  return {
    id: gameSuspensions[0].id,
    purgeId: generateId(),
    game: gameSuspensions[0].servedGames + 1,
    total: gameSuspensions[0].requiredGames,
  }
}
