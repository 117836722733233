const decaySchedule = [
  1000 * 5, // After 5 seconds
  1000 * 30, // After 30 seconds
  1000 * 45, // After 45 seconds
];

const getDelay = attempt => decaySchedule[attempt % decaySchedule.length] || 0;
const wait = delay => new Promise(resolve => setTimeout(resolve, delay || 0));

const retryInterceptor = instance => err => {
  const { config, request, response, isAxiosError } = err;
  if (!isAxiosError || !config) return Promise.reject(err);

  const status = response?.status || request?.status;
  if (status !== 503 && status !== 0) return Promise.reject(err);

  config.__retryAttempts = config.__retryAttempts || 0;
  if (config.__retryAttempts > 3) {
    console.log(`Aborting retrying failed request (${status}) after ${config.__retryAttempts} attempts, ${config.url}`) /* eslint-disable-line no-console */
    return Promise.reject(err);
  }

  const delay = getDelay(config.__retryAttempts)
  console.log(`Retrying (attempt ${config.__retryAttempts}) failed request (${status}) in ${delay/1000}s, ${config.url}`) /* eslint-disable-line no-console */

  config.__retryAttempts += 1;
  return wait(delay).then(() => instance(config))
}

export default (instance) => {
  instance.interceptors.response.use(undefined, retryInterceptor(instance))
}
