import { set } from "lodash";

export function handleValidationError(resource, response) {
  const codes = response.data.error.details.codes;
  return Object.keys(codes).reduce((errors, property) => {
    const error = codes[property];

    const messages = [];
    const setError = value => {
      messages.push(value);
    }

    const isProperty = (res, prop) => resource === res && property === prop;

    if (error.includes('custom.unique')) {
      if (isProperty('games', 'number')) setError('resources.games.validations.number_exists');
      if (isProperty('surfaces', 'alias')) setError('ra.validation.already_exists');
      if (isProperty('teams', 'HCRId')) setError('ra.validation.already_exists');
    }
    if (error.includes('custom.conflicts')) setError(`resources.${resource}.validations.conflicts`)
    if (error.includes('custom.inheritance')) setError(`resources.${resource}.validations.inheritance`)
    if (error.includes('custom.pay_period_closed')) setError(`resources.${resource}.validations.pay_period_closed`)
    if (error.includes('custom.from_pay_period_closed')) setError(`resources.${resource}.validations.from_pay_period_closed`)
    if (error.includes('custom.overlaps')) setError(`resources.${resource}.validations.overlaps`)
    if (error.includes('custom.end_before_start')) setError(`resources.${resource}.validations.end_before_start`)
    if (error.includes('custom.required')) setError('ra.validation.required')
    if (error.includes('custom.claim_approved')) setError('resources.officialClaims.validations.claim_approved')
    if (error.includes('custom.assign_fee_in_use')) setError('resources.assignFees.validations.assign_fee_in_use')
    if (error.includes('custom.delete_assign_fee_in_use')) setError('resources.assignFees.validations.delete_assign_fee_in_use')
    if (error.includes('custom.cannot_archive')) setError('resources.assignFees.validations.cannot_archive')
    if (error.includes('custom.archived_fee')) setError('resources.assignRules.validations.archived_fee')
    if (error.includes('custom.scoring_mode_change')) setError('resources.scheduleSettings.validations.scoring_mode_change')
    if (error.includes('custom.penalty_mode_change')) setError('resources.scheduleSettings.validations.penalty_mode_change')
    if (error.includes('custom.too_many_characters')) setError('resources.schedulesequences.validations.too_many_characters')
    if (error.includes('custom.cannot_contain_consecutive_dashes')) setError('resources.schedulesequences.validations.cannot_contain_consecutive_dashes')
    if (error.includes('custom.cannot_contain_special_characters')) setError('resources.schedulesequences.validations.cannot_contain_special_characters')
    if (error.includes('custom.must_contain_a_number')) setError('resources.schedulesequences.validations.must_contain_a_number')
    if (error.includes('custom.sequence_in_use')) setError('resources.schedulesequences.validations.sequence_in_use')
    if (error.includes('custom.delete_sequence_in_use')) setError('resources.schedulesequences.validations.sequence_in_use')
    if (error.includes('custom.delete_scheduleteam')) setError('resources.schedulesequences.validations.delete_scheduleteam')
    if (error.includes('custom.unique_prefix_suffix')) setError('resources.schedulesequences.validations.unique_prefix_suffix')
    if (error.includes('custom.prefix_cant_end_in_number')) setError('resources.schedulesequences.validations.prefix_cant_end_in_number')
    if (error.includes('custom.greater_than_current')) setError('resources.schedulesequences.validations.greater_than_current')
    if (error.includes('custom.schedule_has_sequence')) setError('resources.schedulesequences.validations.schedule_has_sequence')
    if (error.includes('custom.group_has_sequence')) setError('resources.schedulesequences.validations.group_has_sequence')
    if (error.includes('custom.sequence_overlaps')) setError('resources.schedulesequences.validations.sequence_overlaps')
    if (error.includes('custom.sequence_exhausted')) setError('resources.schedulesequences.validations.sequence_exhausted')
    if (error.includes('custom.excessive_pad_length')) setError('resources.schedulesequences.validations.excessive_pad_length')
    if (error.includes('custom.team_not_in_cross_scheduling_groups')) setError('resources.games.validations.team_not_in_cross_scheduling_groups')
    if (error.includes('custom.teams_in_same_cross_scheduling_group')) setError('resources.games.validations.teams_in_same_cross_scheduling_group')
    if (error.includes('custom.team_in_both_cross_scheduling_groups')) setError('resources.games.validations.team_in_both_cross_scheduling_groups')
    if (error.includes('custom.unique_target')) setError('resources.assignTargets.validations.unique_target')
    if (error.includes('custom.unique_group')) setError('resources.assignTargets.validations.unique_group')

    if (error.includes('custom.Unauthorized')) setError('ra.validation.unauthorized')

    if (Array.isArray(error)) {
      if (error.includes('presence')) setError('ra.validation.required');
      if (error.includes('absence')) setError('ra.validation.absence');
      if (error.includes('length')) setError('ra.validation.length');
      if (error.includes('numericality')) setError('ra.validation.numericality');
      if (error.includes('inclusion')) setError('ra.validation.inclusion');
      if (error.includes('exclusion')) setError('ra.validation.exclusion');
      if (error.includes('format')) setError('ra.validation.format');
      if (error.includes('uniqueness')) setError('ra.validation.uniqueness');
      if (error.includes('date')) setError('ra.validation.date');
    }

    const message = messages.length ? messages[0] : 'ra.validation.invalid';
    set(errors, property, message);
    return errors;
  }, {})
}

export function handleInternalError(resource, response) {
  if (resource === 'participants' || resource === 'teams') {
    return { HCRId: 'ra.validation.already_exists' }
  }

  return {};
}
